<template>
  <div v-if="show" :class="['v-alert-local', 'v-alert-local--' + theme]">
    <div class="v-alert-local__icon">
      <img v-if="customIcon" :src="customIcon" alt="" />
      <svg-icon v-else :name="theme" v-bind="{ [theme]: true }" />
    </div>

    <div class="v-alert-local__content">
      <slot></slot>
    </div>

    <button
      v-if="dismissible"
      class="v-alert-local__dismiss-button"
      @click="dismiss()"
    >
      <v-icon name="x" width="10" height="10" />
    </button>
  </div>
</template>

<script>
import SvgIcon from "./svg-icon";

export default {
  components: { "svg-icon": SvgIcon },
  props: {
    warning: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    mobilepay: {
      type: Boolean,
      default: false
    },
    customIcon: {
      type: String,
      default: null
    },
    dismissible: {
      type: Boolean,
      default: false
    },
    dismissibleId: {
      type: String,
      default: null
    }
  },
  computed: {
    theme() {
      if (this.warning) return "warning";
      if (this.success) return "success";
      if (this.error) return "error";
      if (this.mobilepay) return "mobilepay";
      return "info"; // fallback to 'info'
    }
  },
  data() {
    return {
      show: true,
      alertKey: "dismissedAlerts"
    };
  },
  mounted() {
    //check if in local storage and if so, hide it
    const alerts = JSON.parse(localStorage.getItem(this.alertKey));
    if (alerts && alerts.includes(this.dismissibleId)) {
      this.show = false;
    }
  },
  methods: {
    dismiss() {
      this.show = false;
      if (!this.dismissibleId) return;
      let savedAlerts = JSON.parse(localStorage.getItem(this.alertKey));
      if (savedAlerts) {
        savedAlerts = savedAlerts + " " + this.dismissibleId;
      } else {
        savedAlerts = this.dismissibleId;
      }
      localStorage.setItem(this.alertKey, JSON.stringify(savedAlerts));
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-alert-local {
  --color-warning-fg: 118, 60, 25;
  --color-warning-bg: 253, 253, 235;
  --color-success-fg: 0, 84, 64;
  --color-success-bg: 242, 249, 247;
  --color-error-fg: 161, 29, 32;
  --color-error-bg: 254, 242, 242;
  --color-mobilepay-fg: 14, 66, 156;
  --color-mobilepay-bg: 234, 245, 254;
  --theme-color-fg: 14, 66, 156; // default for info alert
  --theme-color-bg: 234, 245, 254; // default for info alert

  --theme-bg: rgba(var(--theme-color-bg), 1);
  --theme-border: rgba(var(--theme-color-fg), 0.1);
  --theme-text: rgba(var(--theme-color-fg), 1);
  --theme-icon: rgba(var(--theme-color-fg), 0.6);

  position: relative;
  padding: $spacer;
  border-radius: $border-radius * 0.5;
  transition: all 300ms ease-out;
  background-color: var(--theme-bg);
  border: 1px solid var(--theme-border);

  &--warning {
    --theme-color-fg: var(--color-warning-fg);
    --theme-color-bg: var(--color-warning-bg);
  }

  &--success {
    --theme-color-fg: var(--color-success-fg);
    --theme-color-bg: var(--color-success-bg);
  }

  &--error {
    --theme-color-fg: var(--color-error-fg);
    --theme-color-bg: var(--color-error-bg);
  }

  &--mobilepay {
    --theme-color-fg: var(--color-mobilepay-fg);
    --theme-color-bg: var(--color-mobilepay-bg);
    img {
      transform: scale(1.1);
    }
  }

  &-leave-to {
    opacity: 0;
  }

  &__icon {
    position: absolute;
    top: 0.9 * $spacer;
    left: $spacer;
    display: flex;
    justify-content: center;
    pointer-events: none;
    img {
      width: 24px;
      height: 24px;
    }
    svg {
      color: var(--theme-icon);
      fill: currentColor;
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    padding: 0 0px 0 24px;
    margin: 0 $spacer 0 $spacer;
    width: 95%;
    color: var(--theme-text);

    @include media-breakpoint-down(sm) {
      width: 90%;
    }

    ::v-deep {
      a,
      a:visited {
        color: var(--theme-text);
        text-decoration: underline;
      }
    }
  }

  &__dismiss-button {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    border: none;
    cursor: pointer;
    background: none;
    ::v-deep svg {
      color: var(--theme-icon);
    }
  }
}
</style>
